import axios, { AxiosError } from "axios";
import { useMutation, useQueryClient } from "react-query";
import { PersistedTask } from "./shared";
import { ALL_TASKS_QUERY_KEY } from "./useTasks";

export const USE_FINISH_TASK_MUTATION_KEY = "finishTask";

export type FinishTaskArgs = {
  id: string;
};

export function useFinishTask() {
  const queryClient = useQueryClient();
  return useMutation<PersistedTask, AxiosError, FinishTaskArgs>(
    USE_FINISH_TASK_MUTATION_KEY,
    async ({ id }) => {
      const resp = await axios.put<PersistedTask>(
        `/api/proxy/v1/tasks/${id}/finish`,
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      return resp.data;
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries([ALL_TASKS_QUERY_KEY]);
      },
    }
  );
}
