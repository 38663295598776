import * as Sentry from "@sentry/nextjs";
import React, { useMemo } from "react";
import toast from "react-hot-toast";
import { PersistedSubtaskState, PersistedTask, Subtask, Task } from "./shared";
import { useToggleTasks } from "./useToggleSubtask";

export interface TaskCardSubtasksProps {
  task: Task;
  persistedState?: PersistedTask;
  moduleStatus: "IN_PROGRESS" | "COMPLETED" | "SUBMITTED" | null;
}

interface FullTask extends Subtask {
  index: number;
  persistedState: PersistedSubtaskState;
}

const TaskCardSubtasks: React.FC<TaskCardSubtasksProps> = ({
  task,
  persistedState,
  moduleStatus,
}) => {
  const toggleTask = useToggleTasks();

  // Combine the subtask as defined in the frontend with the persisted state
  // from the backend for convenience
  const fullTasks: FullTask[] = useMemo(
    () =>
      task.subtasks
        .map<FullTask | undefined>((subtask, index) => {
          if (persistedState && subtask.codeWord in persistedState.subtasks) {
            return {
              ...subtask,
              index: index,
              persistedState: persistedState.subtasks[subtask.codeWord],
            };
          }

          return undefined;
        })
        .filter((task): task is FullTask => !!task),
    [task, persistedState]
  );

  const getLastCompletedIndex = () => {
    const completedItems = fullTasks.filter((t) => !!t.persistedState);

    if (completedItems.length < 1) {
      return -1;
    }

    return fullTasks.findIndex(
      (t) => t.codeWord === completedItems[completedItems.length - 1].codeWord
    );
  };

  const lastCompletedIndex = getLastCompletedIndex();

  return (
    <div className="space-y-4">
      {fullTasks.map((subtask, index) => {
        const isDisabled =
          index > lastCompletedIndex + 1 ||
          (persistedState && !!persistedState.finishedAt);
        return (
          <div key={subtask.codeWord} className="flex items-start space-x-4">
            <input
              type="checkbox"
              disabled={isDisabled}
              defaultChecked={!!subtask.persistedState}
              onChange={() =>
                persistedState &&
                toggleTask
                  .mutateAsync({
                    id: persistedState.id,
                    codeWord: subtask.codeWord,
                  })
                  .catch((error) => {
                    toast.error(
                      "Oops... something went wrong saving your checklist"
                    );
                    Sentry.captureException(error);
                  })
              }
              className={`mt-0.5 h-5 w-5 rounded-md ${
                isDisabled
                  ? "border-disabledGrey bg-disabledGrey text-disabledGrey"
                  : "text-deepTeal"
              } `}
            />
            <div
              className="flex flex-col space-y-2"
              id="task-card-subtasks-body"
            >
              <span className="font-regular">
                {moduleStatus === "COMPLETED" &&
                subtask.showModuleCompletionStatus ? (
                  <>
                    {index + 1}.&nbsp;{subtask.title}&nbsp;
                    <span className="text-darkGrey">[ FORM COMPLETED ]</span>
                  </>
                ) : (
                  <>
                    {index + 1}.&nbsp;{subtask.title}
                  </>
                )}
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default TaskCardSubtasks;
