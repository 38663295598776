import React from "react";
import { DefaultGoalCard } from "./DefaultGoalCard";
import { GoalCard } from "./GoalCard";
import { goals } from "./goals";

interface GoalsCardsProps {
  children?: React.ReactNode;
}

const GoalsCards: React.FC<GoalsCardsProps> = ({ children }) => {
  const priorityGoals = goals.filter((goal) => goal.priorityDisplay);
  return (
    <>
      <div className="space-y-8">
        <h2 className="text-xl font-medium">I want funding for</h2>
        {children ? (
          <div className="flex flex-col lg:flex-row">
            <div className="mb-3 grid flex-auto grid-cols-1 gap-4 lg:grid-cols-3">
              {priorityGoals.map((goal, idx) => {
                return (
                  <GoalCard
                    {...goal}
                    className="py-4"
                    key={goal.name}
                    hideSm={idx > 4}
                  />
                );
              })}
              <DefaultGoalCard />
            </div>
            <div className="m-auto flex-none">{children}</div>
          </div>
        ) : (
          <div className="grid grid-cols-1 gap-4 lg:grid-cols-5">
            {goals.map((goal, idx) => {
              return <GoalCard {...goal} key={goal.name} hideSm={idx > 4} />;
            })}
            <DefaultGoalCard />
          </div>
        )}
      </div>
    </>
  );
};

export default GoalsCards;
