import { useRouter } from "next/router";
import { Goal } from "./goals";

export const GoalCard: React.FC<
  Goal & { hideSm: boolean; className?: string }
> = ({ name, icon: Icon, goal, hideSm, className }) => {
  const router = useRouter();
  return (
    <button
      onClick={() => router.push(`/programs?fundingGoals=${goal}`)}
      className={`flex-1 grow flex-row items-center space-x-4 space-y-2 rounded-md border border-borderGrey bg-white px-4 py-2 text-sm lg:flex-col lg:justify-center lg:space-x-0 lg:px-2 ${
        hideSm ? "hidden lg:flex" : "flex"
      } ${className}`}
    >
      {Icon ? (
        <Icon className="h-8 w-8 fill-current text-darkGreen opacity-40" />
      ) : null}
      <span className="lg:flex-1">{name}</span>
    </button>
  );
};
