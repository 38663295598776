import * as Sentry from "@sentry/nextjs";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { WithLoadingButton } from "~/components/common/buttons/WithLoadingButton";
import { BaseTaskCard, TaskCardProps } from "./BaseTaskCard";
import { StartButton } from "./StartButton";
import TaskCardSubtasks from "./TaskCardSubtasks";
import { useFinishTask } from "./useFinishTask";

const ActiveTaskCard: React.FC<TaskCardProps> = ({
  task,
  persistedState,
  ...props
}) => {
  const finishTask = useFinishTask();
  const allSubTasksCompleted = !!persistedState
    ? Object.values(persistedState.subtasks).every((st) => !!st)
    : false;

  const [showFinishTaskError, setShowFinishTaskError] =
    useState<boolean>(false);

  return (
    <BaseTaskCard
      task={task}
      persistedState={persistedState}
      {...props}
      variant="active"
    >
      {({ startNewTask, startTaskIsLoading }) => {
        if (persistedState) {
          return (
            <div className="space-y-8">
              {!persistedState.finishedAt && (
                <TaskCardSubtasks
                  task={task}
                  persistedState={persistedState}
                  {...props}
                />
              )}
              <div className="flex">
                {!persistedState.finishedAt ? (
                  <div className="flex items-center gap-4">
                    <div>
                      <WithLoadingButton
                        disabled={!allSubTasksCompleted}
                        color="petrolGreen"
                        onClick={() => {
                          if (props.moduleStatus === "COMPLETED") {
                            finishTask
                              .mutateAsync({
                                id: persistedState.id,
                              })
                              .catch((error) => {
                                toast.error(
                                  "Oops... something went wrong finishing this task"
                                );
                                Sentry.captureException(error);
                              });
                          } else {
                            setShowFinishTaskError(true);
                          }
                        }}
                        isLoading={finishTask.isLoading}
                        customLoadingText="finishing task..."
                      >
                        finish
                      </WithLoadingButton>
                    </div>
                    {showFinishTaskError && (
                      <span className="flex-1 text-sm italic text-errorRed">
                        Complete the form before finishing this task
                      </span>
                    )}
                  </div>
                ) : (
                  <>
                    <WithLoadingButton
                      variant="Secondary"
                      color="petrolGreen"
                      size="sm"
                      onClick={startNewTask}
                      isLoading={startTaskIsLoading}
                      customLoadingText={"restarting task..."}
                    >
                      Restart Task
                    </WithLoadingButton>
                  </>
                )}
              </div>
            </div>
          );
        } else {
          return (
            <div className="flex items-center gap-4">
              <StartButton
                startNewTask={startNewTask}
                isLoading={startTaskIsLoading}
              />
            </div>
          );
        }
      }}
    </BaseTaskCard>
  );
};

export default ActiveTaskCard;
