import { useRouter } from "next/router";
import { useEffect, useState } from "react";

// Utility hook to tell if the router is in the process of changing routes
export const useRouterLoading = () => {
  const [routerIsLoading, setRouterIsLoading] = useState<boolean>(false);

  const router = useRouter();

  useEffect(() => {
    const handleComplete = () => setRouterIsLoading(false);

    router.events.on("routeChangeStart", () => setRouterIsLoading(true));

    router.events.on("routeChangeComplete", handleComplete);
    router.events.on("routeChangeError", handleComplete);

    return () => {
      router.events.off("routeChangeComplete", handleComplete);
      router.events.off("routeChangeError", handleComplete);
      router.events.off("routeChangeStart", handleComplete);
    };
  }, [router]);

  return routerIsLoading;
};
