import Link from "next/link";
import { Task } from "./shared";

const makeProgramUrl = (programCode: string) => {
  return `/programs/${programCode}`;
};

const FSA_RECORDS_TASK: Task = {
  codeWord: "set-up-fsa-records-self-serve",
  title: "Set up FSA Records",
  moduleId: "fsa",
  maxAward: null,
  infoUrl:
    "https://www.farmraise.com/blog/a-farm-number-what-is-it-and-why-is-it-important",
  programCode: null,
  subtasks: [
    {
      codeWord: "watch-fsa-video",
      title: (
        <>
          Watch <Link href="https://youtu.be/mRHRQvq3d1c">this video</Link> to
          learn how to successfully complete your application
        </>
      ),
    },
    {
      codeWord: "begin-fsa-module",
      title: (
        <>
          Complete your{" "}
          <Link href="/module/fsa-records/start">FSA Records Module</Link>
        </>
      ),
      showModuleCompletionStatus: true,
    },
    {
      codeWord: "check-email-for-pdf",
      title: <>When complete, check your email for your completed PDF</>,
    },
    {
      codeWord: "print-and-sign-pdf",
      title: <>Print and sign the PDF</>,
    },
    {
      codeWord: "take-packet-to-office",
      title: (
        <>
          Take your completed packet to{" "}
          <Link href="https://www.farmers.gov/working-with-us/service-center-locator">
            your local office
          </Link>
        </>
      ),
    },
  ],
};

const EQIP_TASK: Task = {
  codeWord: "apply-for-eqip-self-serve",
  title: "Apply for Conservation Cost Share (EQIP)",
  moduleId: "cpa1200_jotform",
  maxAward: 450000,
  infoUrl: makeProgramUrl("eqip"),
  programCode: "eqip",
  subtasks: [
    {
      codeWord: "watch-eqip-video",
      title: (
        <>
          Watch{" "}
          <Link href="https://biteable.com/watch/3825252/02c0156307f6bd1bac2b08edbfc863db">
            this video
          </Link>{" "}
          to learn how to successfully complete your application
        </>
      ),
    },
    {
      codeWord: "begin-eqip-module",
      title: (
        <>
          Complete your <Link href="/programs/eqip">EQIP Module</Link>
        </>
      ),
      showModuleCompletionStatus: true,
    },
    {
      codeWord: "check-email-for-pdf",
      title: <>When complete, check your email for your completed PDF</>,
    },
    {
      codeWord: "print-and-sign-pdf",
      title: <>Print and sign the PDF</>,
    },
    {
      codeWord: "take-packet-to-office",
      title: (
        <>
          Take your completed packet to{" "}
          <Link href="https://www.farmers.gov/working-with-us/service-center-locator">
            your local office
          </Link>
        </>
      ),
    },
    {
      codeWord: "watch-video-good-impression-usda",
      title: (
        <>
          <a
            href="https://biteable.com/watch/3784722/91dd688d4f20a44d4fdb05a9fba1aa21"
            target="_blank"
            rel="noreferrer"
          >
            Here is a video
          </a>
          &nbsp; on how to make a good first impression with the USDA
        </>
      ),
    },
  ],
};

const CONSERVATION_PLANNING_TASK: Task = {
  codeWord: "conservation-planning",
  title: "Conservation Planning",
  moduleId: null,
  maxAward: null,
  infoUrl: null,
  programCode: null,
  subtasks: [
    // TODO: add video task
    {
      codeWord: "add-farm-maps",
      title: (
        <>
          Farm Maps: Let&apos;s get your farm maps to help inform your plan!
          Please take 5 minutes to complete&nbsp;
          <Link href="https://farmraise.jotform.com/221736835630053">
            this brief form&nbsp;
          </Link>
          that will allow us to get your Farm Maps, either from you or your
          local FSA office.
        </>
      ),
    },
    {
      codeWord: "complete-conservation-planning-module",
      title: (
        <>
          Complete the conservation planning module&nbsp;
          <Link href="https://form.jotform.com/221457052826051">here</Link>. It
          will only take you about 15 minutes to complete and allows us to
          understand your current conservation efforts and future goals.
        </>
      ),
    },
    {
      codeWord: "review-conservation-plan",
      title: (
        <>
          Look out for a message from us to review your draft conservation plan
          when it&apos;s ready.
        </>
      ),
    },
    {
      codeWord: "schedule-call",
      title: (
        <>
          <Link href="https://calendly.com/farmraise/truterra-conservation-plan">
            Schedule a conservation planning call
          </Link>
          &nbsp; if you need help with any of the above.
        </>
      ),
    },
  ],
};

export const DefaultTasks: Task[] = [FSA_RECORDS_TASK, EQIP_TASK];

export const TruterraGoldExtraTasks: Task[] = [CONSERVATION_PLANNING_TASK];
