import cntl from "cntl";
import React from "react";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "Primary" | "Secondary" | "Text";
  color?:
    | "darkGreen"
    | "petrolGreen"
    | "focusBlue"
    | "deepTeal"
    | "deepBlue"
    | "blueDiamondBlue"
    | "ggrassDarkGreen"
    | "ggrassMidnightBlue";
  size?: "sm" | "md" | "full";
  additionalClassNames?: string;
}

const Button: React.FC<ButtonProps> = ({
  children,
  variant = "Primary",
  color = "darkGreen",
  size = "md",
  ...props
}) => {
  const baseCN =
    variant === "Text"
      ? `
           ${(() => {
             switch (color) {
               case "darkGreen":
                 return "text-darkGreen";
               case "petrolGreen":
                 return "text-petrolGreen";
               case "focusBlue":
                 return "text-focusBlue";
               case "deepTeal":
                 return "text-deepTeal";
               case "deepBlue":
                 return "text-deepBlue";
               case "blueDiamondBlue":
                 return "text-white";
               case "ggrassMidnightBlue":
                 return "text-ggrassMidnightBlue";
               case "ggrassDarkGreen":
                 return "text-ggrassDarkGreen";
               default:
                 "";
             }
           })()}
          underline
          cursor-pointer
        `
      : cntl`
    inline-flex
    items-center
    ${props.disabled ? "cursor-not-allowed" : "cursor-pointer"}
    ${size === "md" ? "px-12" : "px-6"}
    ${size === "full" ? "w-full" : "sm:w-auto"}
    w-full
    justify-center
    py-2
    border
    text-sm
    font-medium
    rounded-md
    shadow-sm
    ${
      props.disabled
        ? "bg-lightGrey text-disabledGrey"
        : variant === "Primary"
        ? cntl`
           ${(() => {
             switch (color) {
               case "darkGreen":
                 return "bg-darkGreen focus:ring-darkGreen hover:bg-darkGreen/75";
               case "petrolGreen":
                 return "bg-petrolGreen focus:ring-petrolGreen hover:bg-petrolGreen/75";
               case "focusBlue":
                 return "bg-focusBlue focus:ring-focusBlue hover:bg-focusBlue/75";
               case "deepTeal":
                 return "bg-deepTeal focus:ring-deepTeal hover:bg-deepTeal/75";
               case "deepBlue":
                 return "bg-deepBlue focus:ring-deepBlue hover:bg-deepBlue/75";
               case "blueDiamondBlue":
                 return "bg-blueDiamondBlue focus:ring-blueDiamondBlue hover:bg-blueDiamondBlue/75";
               case "ggrassMidnightBlue":
                 return "bg-ggrassMidnightBlue focus:ring-ggrassMidnightBlue hover:bg-ggrassMidnightBlue/75";
               case "ggrassDarkGreen":
                 return "bg-ggrassDarkGreen focus:ring-ggrassDarkGreen hover:bg-ggrassDarkGreen/75";
               default:
                 "";
             }
           })()}
          ${
            color == "ggrassMidnightBlue"
              ? "text-ggrassSkyBlue font-['Lato']"
              : "text-white"
          }
          focus:outline-none
          focus:ring-2
          focus:ring-offset-2
        `
        : cntl`
          border-darkGreen
          bg-white
          ${(() => {
            switch (color) {
              case "darkGreen":
                return "text-darkGreen border-darkGreen focus:ring-darkGreen hover:bg-darkGreen/5";
              case "petrolGreen":
                return "text-petrolGreen border-petrolGreen focus:ring-petrolGreen hover:bg-petrolGreen/5";
              case "focusBlue":
                return "text-focusBlue border-focusBlue focus:ring-focusBlue hover:bg-focusBlue/5";
              case "deepTeal":
                return "text-deepTeal border-deepTeal focus:ring-deepTeal hover:bg-deepTeal/5";
              case "deepBlue":
                return "text-deepBlue border-deepBlue focus:ring-deepBlue hover:bg-deepBlue/5";
              default:
                "";
            }
          })()}
          focus:outline-none
          focus:ring-1
          focus:ring-offset-2
        `
    }
  `;

  return (
    <button className={`${baseCN} ${props.additionalClassNames}`} {...props}>
      {children}
    </button>
  );
};

export default Button;
