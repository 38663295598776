import { useRouter } from "next/router";

export const DefaultGoalCard: React.FC = () => {
  const router = useRouter();
  return (
    <button
      onClick={() => router.push("/programs")}
      className="flex items-center justify-center rounded-md border border-borderGrey bg-lightGrey p-2 text-sm"
    >
      View all funding programs
    </button>
  );
};
