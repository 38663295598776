import axios, { AxiosError } from "axios";
import { useQuery } from "react-query";
import { DataListResponse } from "~/lib/core/DataListResponse";

export type Partnership = "truterra_silver" | "truterra_gold" | null;
export type Subscription = "legacy" | null;

export interface ModuleStatus {
  status: "IN_PROGRESS" | "COMPLETED" | "SUBMITTED";
  entityId: string;
  moduleName: string;
}

export const STATUS_QUERY_KEY = "modules/statuses";

export const useModuleStatuses = (
  {
    isEnabled = true,
  }: {
    isEnabled?: boolean;
  } = { isEnabled: true }
) =>
  useQuery<DataListResponse<ModuleStatus>, AxiosError>(
    STATUS_QUERY_KEY,
    async () => {
      return (
        await axios.get<DataListResponse<ModuleStatus>>(
          "/api/proxy/v1/modules/statuses"
        )
      ).data;
    },
    {
      enabled: isEnabled,
    }
  );
