import axios, { AxiosError } from "axios";
import { useQuery } from "react-query";
import { PersistedTask } from "./shared";

export const ALL_TASKS_QUERY_KEY = "getAllTasks";

export function useTasks() {
  return useQuery<PersistedTask[], AxiosError>(
    ALL_TASKS_QUERY_KEY,
    async () => {
      const result = await axios.get<PersistedTask[]>(`/api/proxy/v1/tasks`, {
        headers: { "Content-Type": "application/json" },
      });

      return result.data;
    }
  );
}
