import { WithLoadingButton } from "~/components/common/buttons/WithLoadingButton";

export const StartButton: React.FC<{
  startNewTask: () => void;
  isLoading: boolean;
}> = ({ startNewTask, isLoading }) => {
  return (
    <WithLoadingButton
      size="sm"
      color="petrolGreen"
      variant="Secondary"
      onClick={startNewTask}
      isLoading={isLoading}
      customLoadingText="Starting..."
    >
      Start
    </WithLoadingButton>
  );
};
