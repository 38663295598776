import axios, { AxiosError } from "axios";
import { useQuery } from "react-query";
import { SupportedModule } from "../pricing/types";

export type Partnership = "truterra_silver" | "truterra_gold" | null;
export type Subscription = "legacy" | null;

export interface Products {
  subscription: Subscription;
  partnership: Partnership;
  products: SupportedModule[];
  canGetLegacyFreeProduct: boolean;
}

export const PRODUCTS_QUERY_KEY = "products";

export const useProducts = () =>
  useQuery<Products | undefined, AxiosError>(
    PRODUCTS_QUERY_KEY,
    async () => {
      return (await axios.get<Products>("/api/proxy/v1/farmer/products")).data;
    },
    {
      useErrorBoundary: (error) => {
        if (error && axios.isAxiosError(error) && error.response?.status) {
          return error.response?.status !== 401;
        }

        return false;
      },
    }
  );
