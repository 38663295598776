import React from "react";
import { CloseCircled } from "~/components/assets/icons/close-circled";
import { Spinner } from "~/components/assets/icons/spinner";
import { useModuleStatuses } from "~/components/hooks/useModuleStatuses";
import { Partnership, useProducts } from "~/components/hooks/useProducts";
import { useUser } from "~/components/hooks/useUser";
import ActiveTaskCard from "./ActiveTaskCard";
import { DefaultTasks, TruterraGoldExtraTasks } from "./data";
import InactiveTaskCard from "./InactiveTaskCard";
import { Task } from "./shared";
import { useTasks } from "./useTasks";

const getTasks = (partnership: Partnership) => {
  switch (partnership) {
    case "truterra_gold":
      return [...TruterraGoldExtraTasks, ...DefaultTasks];
    default:
      return DefaultTasks;
  }
};

const TaskCards: React.FC = () => {
  const {
    data: persistedTasks,
    isLoading: isTasksLoading,
    isError: isTasksError,
  } = useTasks();
  const {
    data: products,
    isLoading: isProductsLoading,
    isError: isProductsError,
  } = useProducts();
  const moduleStatuses = useModuleStatuses();
  const {
    data: user,
    isLoading: isUserLoading,
    isError: isUserError,
  } = useUser();

  // Gets the latest task for a given codeWord if one exists. If multiple exist,
  // will return the one with the latest startedAt timestamp
  const getLatestTask = (codeWord: string) =>
    persistedTasks?.find(
      (persistedTask) => persistedTask.codeWord === codeWord
    );

  if (
    isTasksLoading ||
    isProductsLoading ||
    moduleStatuses.isLoading ||
    isUserLoading
  ) {
    return (
      <div className="flex items-center gap-4 rounded-md bg-lightGrey p-4">
        <Spinner className="h-5 w-5 animate-spin fill-current text-darkGreen" />
        <span>Loading your tasks...</span>
      </div>
    );
  }

  if (
    isTasksError ||
    isProductsError ||
    moduleStatuses.isError ||
    isUserError
  ) {
    return (
      <div className="flex items-center gap-4 rounded-md bg-lightGrey p-4">
        <CloseCircled className="h-5 w-5 fill-current text-darkBlue" />
        <span>Oops... something went wrong getting your tasks</span>
      </div>
    );
  }

  const isPurchased = (): boolean | null => {
    return (
      user?.isMember ||
      products?.partnership == "truterra_gold" ||
      products?.partnership == "truterra_silver"
    );
  };

  const moduleStatus = (task: Task) => {
    return moduleStatuses.data?.data?.find(
      (status) => task.moduleId != null && status.moduleName === task.moduleId
    );
  };

  var tasks = getTasks(products?.partnership || null).map((task) => {
    const persistedTask = getLatestTask(task.codeWord);
    return { task, persistedTask };
  });

  return (
    <>
      <div className="space-y-8">
        <h2 className="text-xl font-medium">Tasks</h2>
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
          {tasks.map(({ task, persistedTask }) => {
            return (
              <div key={task.codeWord + persistedTask?.id}>
                {isPurchased() ? (
                  <ActiveTaskCard
                    task={task}
                    persistedState={persistedTask}
                    moduleStatus={moduleStatus(task)?.status ?? null}
                  />
                ) : (
                  <InactiveTaskCard
                    task={task}
                    key={task.codeWord + persistedTask?.id}
                    persistedState={persistedTask}
                    moduleStatus={moduleStatus(task)?.status ?? null}
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default TaskCards;
