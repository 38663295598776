import { FC } from "react";
import AppDownloadBadges from "../common/AppDownloadBadges";

export const TracksBanner: FC = () => {
  return (
    <div className="bg-tracks-banner-sm bg-no-repeat px-5 py-6 drop-shadow-banner min-[460px]:px-10 md:bg-tracks-banner-md">
      <h2 className="text-[28px] font-semibold text-darkGreen lg:mt-1.5 lg:text-[34px]">
        FarmRaise Tracks
      </h2>
      <p className="mb-5 max-w-sm text-xl text-darkGreen lg:text-2xl lg:leading-10">
        Track farm expenses, grow your business - it’s that simple.
      </p>
      <AppDownloadBadges wrapperCN="flex items-start -ml-2 min-[460px]:-ml-3" />
    </div>
  );
};
