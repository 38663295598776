import { useRouter } from "next/router";
import { useState } from "react";
import { WithLoadingButton } from "~/components/common/buttons/WithLoadingButton";
import { useRouterLoading } from "~/components/hooks/useRouterLoading";

export const BuyButton: React.FC = () => {
  const router = useRouter();
  const routerLoading = useRouterLoading();
  const [wasSelected, setWasSelected] = useState<boolean>(false);

  return (
    // <div className="w-24 sm:w-auto">
    <WithLoadingButton
      isLoading={routerLoading && wasSelected}
      loadingChildrenClassName="text-white"
      size="md"
      color="focusBlue"
      variant="Primary"
      onClick={() => {
        setWasSelected(true);
        router.push(`/pricing`);
      }}
    >
      Upgrade
    </WithLoadingButton>
    // </div>
  );
};
