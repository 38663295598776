import Link from "next/link";
import React, { ReactNode } from "react";
import { BaseTaskCard, TaskCardProps } from "./BaseTaskCard";
import { BuyButton } from "./BuyButton";
import { Task } from "./shared";

const InactiveTaskCard: React.FC<TaskCardProps> = ({ task, ...props }) => {
  const getMoreInfoComponent = (task: Task): ReactNode => (
    <>
      {task.infoUrl && (
        <Link href={task.infoUrl}>
          <span className="cursor-pointer whitespace-nowrap text-darkBlue underline">
            More info
          </span>
        </Link>
      )}
    </>
  );

  return (
    <BaseTaskCard task={task} variant="inactive" {...props}>
      {() => {
        return (
          <div className="flex items-center gap-4">
            <BuyButton />
            {getMoreInfoComponent(task)}
          </div>
        );
      }}
    </BaseTaskCard>
  );
};

export default InactiveTaskCard;
