import React from "react";
import ModalContainer, { ModalContainerProps } from "../ModalContainer";

const VideoModal: React.FC<ModalContainerProps> = (props) => {
  return (
    <ModalContainer {...props}>
      <div className="flex flex-col items-center space-y-5">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/CCAtBCING90"
          allowFullScreen
          allow="autoplay"
        ></iframe>
      </div>
    </ModalContainer>
  );
};

export default VideoModal;
