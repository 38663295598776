import axios, { AxiosError } from "axios";
import { useMutation, useQueryClient } from "react-query";
import { PersistedTask } from "./shared";
import { ALL_TASKS_QUERY_KEY } from "./useTasks";

export const START_TASK_MUTATION_KEY = "startTask";

export type StartTaskArgs = {
  codeWord: string;
  subtasks: Record<string, null>;
};

export function useStartTask() {
  const queryClient = useQueryClient();
  return useMutation<PersistedTask, AxiosError, StartTaskArgs>(
    START_TASK_MUTATION_KEY,
    async (body) => {
      const resp = await axios.post<PersistedTask>(
        `/api/proxy/v1/tasks/start`,
        body,
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      return resp.data;
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries([ALL_TASKS_QUERY_KEY]);
      },
    }
  );
}
