import { BuyingLandGoal } from "~/components/assets/icons/goals/buying-land";
import { CommodityCropsGoal } from "~/components/assets/icons/goals/commodity-crops";
import { EquipmentGoal } from "~/components/assets/icons/goals/equipment";
import { InteriorFencingGoal } from "~/components/assets/icons/goals/interior-fencing";
import { LivestockGoal } from "~/components/assets/icons/goals/livestock";
import { MarketingGoal } from "~/components/assets/icons/goals/marketing";
import { RegenerativeAgGoal } from "~/components/assets/icons/goals/regenerative-ag";
import { SpecialtyCropsGoal } from "~/components/assets/icons/goals/specialty-crops";
import { StartingFarmGoal } from "~/components/assets/icons/goals/starting-farm";
import { SVGProps } from "~/components/assets/_svg";

export type Goal = {
  name: string;
  icon: React.FC<SVGProps> | null;
  goal: string;
  priorityDisplay: boolean;
};

export const goals: Goal[] = [
  {
    name: "buying land",
    icon: BuyingLandGoal,
    goal: "buying-land",
    priorityDisplay: true,
  },
  {
    name: "commodity crops",
    icon: CommodityCropsGoal,
    goal: "commodity-crops",
    priorityDisplay: true,
  },
  {
    name: "equipment",
    icon: EquipmentGoal,
    goal: "equipment",
    priorityDisplay: false,
  },
  {
    name: "infrastructure",
    icon: InteriorFencingGoal,
    goal: "infrastructure",
    priorityDisplay: false,
  },
  {
    name: "livestock",
    icon: LivestockGoal,
    goal: "livestock",
    priorityDisplay: true,
  },
  {
    name: "marketing",
    icon: MarketingGoal,
    goal: "marketing",
    priorityDisplay: true,
  },
  {
    name: "climate-smart",
    icon: RegenerativeAgGoal,
    goal: "climate-smart",
    priorityDisplay: false,
  },
  {
    name: "speciality crops",
    icon: SpecialtyCropsGoal,
    goal: "speciality-crops",
    priorityDisplay: false,
  },
  {
    name: "starting a business",
    icon: StartingFarmGoal,
    goal: "starting-a-business",
    priorityDisplay: true,
  },
];
