import axios, { AxiosError } from "axios";
import produce from "immer";
import { useMutation, useQueryClient } from "react-query";
import { PersistedTask } from "./shared";
import { ALL_TASKS_QUERY_KEY } from "./useTasks";

export const TOGGLE_SUBTASK_MUTATION_KEY = "toggleSubTask";

export type ToggleTaskArgs = {
  id: string;
  // Codeword for the subtask you are trying to toggle
  codeWord: string;
};

export function useToggleTasks() {
  const queryClient = useQueryClient();

  return useMutation<
    PersistedTask,
    AxiosError,
    ToggleTaskArgs,
    { previousTasks: PersistedTask[] | undefined }
  >(
    TOGGLE_SUBTASK_MUTATION_KEY,
    async ({ id, ...args }) => {
      const resp = await axios.put<PersistedTask>(
        `/api/proxy/v1/tasks/${id}`,
        args,
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      return resp.data;
    },
    {
      // Optimistically update togelling tasks for a responsive UI
      onMutate: async (toggeledTask) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(ALL_TASKS_QUERY_KEY);

        // Save the old data incase shit hits the fan
        const previousTasks =
          queryClient.getQueryData<PersistedTask[]>(ALL_TASKS_QUERY_KEY);

        // Optimistically update with new toggled state
        queryClient.setQueryData<PersistedTask[]>(
          ALL_TASKS_QUERY_KEY,
          (old) => {
            if (!old) {
              throw Error(
                "Trying to pptimistically update task toggled state without tasks "
              );
            }
            const taskIndex = old?.findIndex((v) => v.id === toggeledTask.id);

            if (taskIndex == -1) {
              throw Error(
                `Couldn't find the updated task id of ${toggeledTask.id} in the current context`
              );
            }

            return produce(old, (draft) => {
              draft[taskIndex].subtasks = {
                ...draft[taskIndex].subtasks,
                // Will not be the same timestamp as what the API will save, but
                // thats OK for the current implementation as this timestamp
                // will have no use
                [toggeledTask.codeWord]: !!draft[taskIndex].subtasks[
                  toggeledTask.codeWord
                ]
                  ? null
                  : "PLACEHOLDER",
              };
            });
          }
        );

        return { previousTasks: previousTasks };
      },
      // If the mutation fails, use the context returned from onMutate to roll back
      onError: (err, newTodo, context) => {
        queryClient.setQueryData<PersistedTask[] | undefined>(
          ALL_TASKS_QUERY_KEY,
          context?.previousTasks
        );
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries(ALL_TASKS_QUERY_KEY);
      },
    }
  );
}
