import React from "react";
import { SVGProps } from "../../_svg";

export const LivestockGoal: React.FC<SVGProps> = (props) => (
  <svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M5.625 14.373C7.35089 14.373 8.75 12.9739 8.75 11.248C8.75 9.52216 7.35089 8.12305 5.625 8.12305C3.89911 8.12305 2.5 9.52216 2.5 11.248C2.5 12.9739 3.89911 14.373 5.625 14.373Z" />
    <path d="M11.25 9.37305C12.9759 9.37305 14.375 7.97394 14.375 6.24805C14.375 4.52216 12.9759 3.12305 11.25 3.12305C9.52411 3.12305 8.125 4.52216 8.125 6.24805C8.125 7.97394 9.52411 9.37305 11.25 9.37305Z" />
    <path d="M18.75 9.37305C20.4759 9.37305 21.875 7.97394 21.875 6.24805C21.875 4.52216 20.4759 3.12305 18.75 3.12305C17.0241 3.12305 15.625 4.52216 15.625 6.24805C15.625 7.97394 17.0241 9.37305 18.75 9.37305Z" />
    <path d="M24.375 14.373C26.1009 14.373 27.5 12.9739 27.5 11.248C27.5 9.52216 26.1009 8.12305 24.375 8.12305C22.6491 8.12305 21.25 9.52216 21.25 11.248C21.25 12.9739 22.6491 14.373 24.375 14.373Z" />
    <path d="M21.675 17.948C20.5875 16.673 19.675 15.5855 18.575 14.3105C18 13.6355 17.2625 12.9605 16.3875 12.6605C16.25 12.6105 16.1125 12.573 15.975 12.548C15.6625 12.498 15.325 12.498 15 12.498C14.675 12.498 14.3375 12.498 14.0125 12.5605C13.875 12.5855 13.7375 12.623 13.6 12.673C12.725 12.973 12 13.648 11.4125 14.323C10.325 15.598 9.4125 16.6855 8.3125 17.9605C6.675 19.598 4.6625 21.4105 5.0375 23.948C5.4 25.223 6.3125 26.4855 7.95 26.848C8.8625 27.0355 11.775 26.298 14.875 26.298H15.1C18.2 26.298 21.1125 27.023 22.025 26.848C23.6625 26.4855 24.575 25.2105 24.9375 23.948C25.325 21.398 23.3125 19.5855 21.675 17.948Z" />
  </svg>
);
