import { captureException } from "@sentry/nextjs";
import { FC, ReactNode } from "react";
import toast from "react-hot-toast";
import NumberFormat from "react-number-format";
import { PersistedTask } from "./shared";
import { TaskCardSubtasksProps } from "./TaskCardSubtasks";
import { useStartTask } from "./useStartTask";

export interface TaskCardProps extends TaskCardSubtasksProps {
  persistedState?: PersistedTask;
}

interface BaseTaskProps {
  startNewTask: () => Promise<void | PersistedTask>;
  startTaskIsLoading: boolean;
}

interface BaseTaskCardProps extends TaskCardProps {
  variant: "active" | "inactive";
  children: (props: BaseTaskProps) => ReactNode;
}

export const BaseTaskCard: FC<BaseTaskCardProps> = ({
  task,
  persistedState,
  moduleStatus,
  variant,
  children,
}) => {
  const startTask = useStartTask();

  const startNewTask = () =>
    startTask
      .mutateAsync({
        codeWord: task.codeWord,
        subtasks: task.subtasks
          .map((t) => t.codeWord)
          .reduce((acc, i) => ({ ...acc, ...{ [i]: null } }), {}),
      })
      .catch((error) => {
        toast.error("Oops... something went wrong starting the task");
        captureException(error);
      });

  return (
    <div className="flex flex-1 grow rounded-md border border-borderGrey bg-white">
      <div
        className={`w-2.5 ${
          variant === "active" ? "bg-petrolGreen" : "bg-lightBlue"
        } shrink-0 rounded-l-md`}
      />
      <div className="flex flex-1 flex-col justify-between space-y-8 px-4 py-4">
        <div className="flex grow flex-col gap-1">
          <span className="font-semibold">{task.title}</span>
          {task.maxAward != null ? (
            <span className="text-sm text-darkGrey">
              max award:&nbsp;
              <NumberFormat
                value={task.maxAward}
                displayType={"text"}
                thousandSeparator={true}
                decimalScale={0}
                fixedDecimalScale
                prefix={"$"}
              />
            </span>
          ) : (
            <span className="h-5"> </span>
          )}
        </div>
        {children({
          startNewTask,
          startTaskIsLoading: startTask.isLoading,
        })}
      </div>
    </div>
  );
};
